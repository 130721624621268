import React from 'react'
import { BeatLoader, ClipLoader } from 'react-spinners'
const { REACT_APP_API_GATEWAY, REACT_APP_URL_PATH } = process.env
// import { HashLoader } from 'react-spinners'

function ThemedSuspense() {
  return (
    <div className="w-full min-h-screen text-center p-6  text-lg font-medium text-gray-600 dark:text-gray-400 dark:bg-cool-gray-800">
      {/* <HashLoader size={100} color={"#00FFFF"} /> */}
      <div className="dark:text-white mt-10 mx-4 px-2 flex justify-center items-center">
        <img
          alt="img-login"
          className="bg-blueGray-800 h-full rounded-lg border-0 mx-auto"
          src={`${REACT_APP_API_GATEWAY.replace(REACT_APP_URL_PATH, "")}/assets/gif/extension-apps.gif`}
        />
      </div>
      <div className="text-xl">
        Loading
        <BeatLoader size={5} color={"#000000"} />
        <ClipLoader size={20} color={"#000000"} />
      </div>
    </div>
  )
}

export default ThemedSuspense
