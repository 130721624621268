import {
    GET_WEBHOOK,
} from 'src/api/actions/ServiceReport'

let initialState = {
    getReportWebhook: false,
    errorReportWebhook: false,

}


const ServiceReport = (state = initialState, action) => {
    switch (action.type) {
        case GET_WEBHOOK:
            return {
                ...state,
                getReportWebhook: action.payload.data,
                errorReportWebhook: action.payload.errorMessage,
            };
        default:
            return state;
    }
}
export default ServiceReport