import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import ReduxState from "./ReduxState";
import { ServiceNotifications } from "./ServiceNotifications";
import ServiceOrganization from "./ServiceOrganization";
import ServicePhone from './ServicePhone';
import ServiceReport from './ServiceReport';

export default combineReducers({
    ServiceOrganization,
    ServiceNotifications,
    ServiceReport,
    ServicePhone,
    ReduxState,
    form: formReducer,
});