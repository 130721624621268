
import {
    POST_NOTIFICATIONS_REGISTER,
    POST_NOTIFICATIONS_SEND
} from "src/api/actions/ServiceNotifications"
let initialState = {
    postNotificationsRegister: false,
    erroNotificationsRegister: false,
    postNotificationsSend: false,
    erroNotificationsSend: false,
}

export const ServiceNotifications = (state = initialState, action) => {
    switch (action.type) {
        case POST_NOTIFICATIONS_REGISTER:
            return {
                ...state,
                postNotificationsRegister: action.payload.data,
                erroNotificationsRegister: action.payload.errorMessage,
            };
        case POST_NOTIFICATIONS_SEND:
            return {
                ...state,
                postNotificationsSend: action.payload.data,
                erroNotificationsSend: action.payload.errorMessage,
            };
        default:
            return state;
    }
}