const initialState = {
    client: false,
    users: false,

    statusSip: false,
    incoming: false,
    call: false,
    status: "",
    statusCall: false,
    destination: "",

    // statusSip: true,
    // incoming: false,
    // call: true,
    // status: "incoming",
    // statusCall: "ringing",
    // destination: "6282213758401",

    menu: "dial",
    message: "Trying ...",
    history: {
        status: false,
        destination: false,
        type: false,
        startTime: false,
        answerdTime: false,
        endTime: false,
    },
    destinationdtmf: "",
    destinationtransfer: "",
    dialPad: false,
    transferCall: false,
    permissions: false,
    registerFailed: false,
    ticketZendesk: false,
    getWebhook: false,
    ticketId: false,
    queueName: false,
    showModal: false,
    networks: false,
    notification: false,
    wsClient: false

}
const ReduxState = (state = initialState, { type, ...rest }) => {
    switch (type) {
        case 'set':
            return { ...state, ...rest }
        default:
            return state
    }
}
export default ReduxState;