
import { POST_NOTIFICATIONS_REGISTER } from './NotificationsRegister'
import { POST_NOTIFICATIONS_SEND } from './NotificationSend'


// ==================================== Delete Redux Data Service Notify  ======================================//
export const DeleteReduxDataNotify = () => {
    return (dispatch) => {
        dispatch({
            type: POST_NOTIFICATIONS_REGISTER,
            payload: {
                data: false,
                errorMessage: false,
            },
        });
        dispatch({
            type: POST_NOTIFICATIONS_SEND,
            payload: {
                data: false,
                errorMessage: false,
            },
        });
    }
}
// ==================================== End Delete Redux Data Service Notify ======================================//