import ServiceOrganizationRoute from "src/api/urlRoute/ServiceOrganization/ServiceOrganization";

export const GET_ALL_ORGANIZATION = "GET_ALL_ORGANIZATION"

// ==================================== Get All Organization ======================================//
export const GetAllOrganization = (data) => {
    return async (dispatch) => {
        return await ServiceOrganizationRoute.GetAllOrganization(data)
            .then((response) => {
                if (response.data.length === 0) {
                    dispatch({
                        type: GET_ALL_ORGANIZATION,
                        payload: {
                            data: false,
                            errorMessage: "Data Not Found",
                        },
                    });
                } else {
                    dispatch({
                        type: GET_ALL_ORGANIZATION,
                        payload: {
                            data: response.data,
                            errorMessage: false,
                        },
                    });
                }
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    dispatch({
                        type: GET_ALL_ORGANIZATION,
                        payload: {
                            data: false,
                            errorMessage: error.response.data.message,
                        },
                    });
                } else {
                    dispatch({
                        type: GET_ALL_ORGANIZATION,
                        payload: {
                            data: false,
                            errorMessage: error.message,
                        },
                    });
                }
            });
    };
}
// ==================================== End Get All Organization ======================================//