import ServiceReportRoute from "src/api/urlRoute/ServiceReport/ServiceReportRoute";

export const GET_WEBHOOK = "GET_WEBHOOK"

// ==================================== Get Webhook ======================================//
export const GetWebhook = (param) => {
    return async (dispatch) => {
        return await ServiceReportRoute.GetWebhook(param)
            .then((response) => {
                if (response.data.length === 0) {
                    dispatch({
                        type: GET_WEBHOOK,
                        payload: {
                            data: false,
                            errorMessage: "Data Not Found",
                        },
                    });
                } else {
                    dispatch({
                        type: GET_WEBHOOK,
                        payload: {
                            data: response.data,
                            errorMessage: false,
                        },
                    });
                }
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    dispatch({
                        type: GET_WEBHOOK,
                        payload: {
                            data: false,
                            errorMessage: error.response.data.message,
                        },
                    });
                } else {
                    dispatch({
                        type: GET_WEBHOOK,
                        payload: {
                            data: false,
                            errorMessage: error.message,
                        },
                    });
                }
            });
    };
}
// ==================================== End Get Webhook ======================================//