import {
    GET_SIP_USERS,
    GET_ALL_MASTERS_DIAL_PLANS
} from 'src/api/actions/servicePhone'

let initialState = {
    getSipUsers: false,
    errorSipUsers: false,
    getAllMastersDialPlans: false,
    errorAllMastersDialPlans: false,

}


const ServicePhone = (state = initialState, action) => {
    switch (action.type) {
        case GET_SIP_USERS:
            return {
                ...state,
                getSipUsers: action.payload.data,
                errorSipUsers: action.payload.errorMessage,
            };
        case GET_ALL_MASTERS_DIAL_PLANS:
            return {
                ...state,
                getAllMastersDialPlans: action.payload.data,
                errorAllMastersDialPlans: action.payload.errorMessage,
            };
        default:
            return state;
    }
}
export default ServicePhone