import {
    GET_ALL_ORGANIZATION,
} from 'src/api/actions/ServiceOrganization'

let initialState = {
    getAllOrganization: false,
    errorAllOrganization: false,

}


const ServiceOrganization = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_ORGANIZATION:
            return {
                ...state,
                getAllOrganization: action.payload.data,
                errorAllOrganization: action.payload.errorMessage,
            };
        default:
            return state;
    }
}
export default ServiceOrganization