import ServicePhoneRoute from "src/api/urlRoute/servicePhone/ServicePhoneRoute";

export const GET_ALL_MASTERS_DIAL_PLANS = "GET_ALL_MASTERS_DIAL_PLANS"

// ==================================== Get Sip Users ======================================//
export const GetAllMastersDialPad = (data) => {
    return async (dispatch) => {
        return await ServicePhoneRoute.GetAllMastersDialPad(data)
            .then((response) => {
                if (response.data.length === 0) {
                    dispatch({
                        type: GET_ALL_MASTERS_DIAL_PLANS,
                        payload: {
                            data: false,
                            errorMessage: "Data Not Found",
                        },
                    });
                } else {
                    dispatch({
                        type: GET_ALL_MASTERS_DIAL_PLANS,
                        payload: {
                            data: response.data,
                            errorMessage: false,
                        },
                    });
                }
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    dispatch({
                        type: GET_ALL_MASTERS_DIAL_PLANS,
                        payload: {
                            data: false,
                            errorMessage: error.response.data.message,
                        },
                    });
                } else {
                    dispatch({
                        type: GET_ALL_MASTERS_DIAL_PLANS,
                        payload: {
                            data: false,
                            errorMessage: error.message,
                        },
                    });
                }
            });
    };
}
// ==================================== End Get Sip Users ======================================//