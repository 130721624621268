import React, { Component } from 'react'
import { connect } from 'react-redux';
import {
  HashRouter,
  Route,
  Switch,
} from 'react-router-dom';

const ZendeskWebphone = React.lazy(() => import('src/pages/webphone/Webphone'));
const Background = React.lazy(() => import('src/pages/Background/Background'));
const Login = React.lazy(() => import('src/pages/Login/Login'));

const mapStateToProps = (state) => {
  return {
    getSipUsers: state.ServicePhone.getSipUsers,
    errorSipUsers: state.ServicePhone.errorSipUsers,
    message: state.ReduxState.message,
    status: state.ReduxState.status,
    incoming: state.ReduxState.incoming,
    users: state.ReduxState.users,
    client: state.ReduxState.client,
    menu: state.ReduxState.menu,
    destination: state.ReduxState.destination,
    call: state.ReduxState.call,
    statusSip: state.ReduxState.statusSip,
    permissions: state.ReduxState.permissions,
  };
};

class App extends Component {
  async componentDidMount() {
    Notification.requestPermission()
    if (Notification.permission === 'granted') {
      this.props.dispatch({ type: "set", notification: true })
    }

    navigator.mediaDevices.getUserMedia({ video: false, audio: true }).then(stream => {
      window.localStream = stream; // A
      return this.props.dispatch({ type: "set", permissions: true })
    }).catch(err => {
      console.log("u got an error:" + err)
      return this.props.dispatch({ type: "set", permissions: false })
    })

    if (navigator.onLine) {
      this.props.dispatch({ type: "set", networks: true })
    } else {
      this.props.dispatch({ type: "set", networks: false })
    }

  }
  render() {
    return (
      <>
        <HashRouter>
          <Switch>
            <Route exact path="/zendesk/background" name="Zendesk Webphone" component={Background} replace />
            <Route exact path="/zendesk/webphone" name="Zendesk Webphone" component={ZendeskWebphone} replace />
            <Route exact path="/zendesk/login" name="Zendesk Webphone" component={Login} replace />
          </Switch>
        </HashRouter>
      </>
    )
  }
}

export default connect(mapStateToProps)(App)