import ServiceNotificationsRoute from "src/api/urlRoute/ServiceNotifications/ServiceNotificationsRoute";

export const POST_NOTIFICATIONS_SEND = "POST_NOTIFICATIONS_SEND"

// ==================================== Notifications Send ======================================//
export const NotificationsSend = (data) => {
    return async (dispatch) => {
        return await ServiceNotificationsRoute.NotificationsSend(data)
            .then((response) => {
                dispatch({
                    type: POST_NOTIFICATIONS_SEND,
                    payload: {
                        data: response.data,
                        errorMessage: false,
                    },
                });
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    dispatch({
                        type: POST_NOTIFICATIONS_SEND,
                        payload: {
                            data: false,
                            errorMessage: error.response.data.message,
                        },
                    });
                } else {
                    dispatch({
                        type: POST_NOTIFICATIONS_SEND,
                        payload: {
                            data: false,
                            errorMessage: error.message,
                        },
                    });
                }
            });
    };
}
// ==================================== End Notifications Send ======================================//